<template>
  <div class="Enter-box-Enterprise">
    <p class="Enterprise-p"><i></i><span @click="crumbs">企业认证</span><span>/</span><span>新增企业</span></p>
    <div class="Enterprise">
      <div class="Enterprise-title">
        <span></span>
        <p>企业信息</p>
      </div>
      <div class="Enterprise-mess">
        <div class="Enterprise-mess-name">
          <!-- name1 -->
          <span>企业名称：</span>

          <el-input type="text" placeholder="请输入您所属公司营业执照上的公司名称" v-model="name_comp" maxlength="50" show-word-limit>
          </el-input>
        </div>
        <div class="Enterprise-mess-name">
          <!-- name1 -->
          <span>统一社会信用代码：</span>
          <el-input type="text" placeholder="" @blur="social()" v-model="credit" maxlength="18" show-word-limit>
          </el-input>
          <!--  -->
        </div>
        <div class="Enterprise-mess-name">
          <!-- name1 -->
          <span>上传营业执照：</span>
          <el-upload
:class="{ uoloadSty: showBtnImg, disUoloadSty: noneBtnImg }"
            action="https://www.meilibaobiao.com:8080/hwb_client/V1.0.0"
:on-remove="handleImgRemove"
:limit="1"
            :auto-upload='false'
:on-change="imgChange"
list-type="picture-card"
:on-preview="handlePictureCardPreview">
            >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
          <p class="Enterprise-mess-name-p">
            仅支持JPG/JPEG/PNG/PDF格式，大小不超过5M
          </p>
        </div>
      </div>
      <div class="Enterprise-title">
        <span></span>
        <p>法人信息</p>
      </div>
      <div class="Enterprise-mess">
        <div class="Enterprise-mess-name">
          <!-- name1 -->
          <span>法人姓名：</span>
          <el-input type="text" placeholder="" v-model="name"> </el-input>
        </div>
        <div class="Enterprise-mess-name">
          <!-- name1 -->
          <span>法人手机号：</span>
          <el-input type="text" placeholder="" v-model="text_phone" maxlength="11" show-word-limit>
          </el-input>
        </div>
        <div class="Enterprise-mess-name">
          <span>法人身份证：</span>
          <div class="Enterprise-mess-shang">
            <el-upload
:class="{
                uoloadSty_s: showBtnImg_s,
                disUoloadSty_s: noneBtnImg_s
              }"
action="https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0"
:on-remove="handleImgRemove_s"
:limit="1"
              :on-change="imgChange_s"
:auto-upload='false'
list-type="picture-card"
              :on-preview="handlePictureCardPreview_s">
              >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible_s">
              <img width="100%" :src="dialogImageUrl_s" alt="" />
            </el-dialog>

            <el-upload
:class="{
                uoloadSty_x: showBtnImg_x,
                disUoloadSty_x: noneBtnImg_x
              }"
action="https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0"
:on-remove="handleImgRemove_x"
:limit="1"
              :on-change="imgChange_x"
:auto-upload='false'
list-type="picture-card"
              :on-preview="handlePictureCardPreview_x">
              >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible_x">
              <img width="100%" :src="dialogImageUrl_x" alt="" />
            </el-dialog>
            <p>请上传身份证正面和反面</p>
          </div>
        </div>
        <div @click="immediately()" class="ent-button">
          <p>立即提交</p>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import {
  add_ent_aut_info,
  aut_ent_have_exist
} from '@api/pulic'
export default {
  data() {
    return {
      name_comp: '', // 企业名称
      credit: '', // 信用代码
      name: '', // 法人姓名
      text_phone: '', // 手机号
      textarea: '',
      // 上传图片

      showBtnImg: true,
      showBtnImg_s: true,
      showBtnImg_x: true,

      noneBtnImg: false,
      noneBtnImg_s: false,
      noneBtnImg_x: false,

      limitCountImg: 1,
      limitCountImg_s: 1,
      limitCountImg_x: 1,

      dialogImageUrl: '',
      dialogImageUrl_s: '',
      dialogImageUrl_x: '',

      dialogVisible: false,
      dialogVisible_s: false,
      dialogVisible_x: false,
      imgname: '',
      imgFname1: '',
      imgFname2: '',
      imgFname: '',

      asdd: ''

    }
  },

  methods: {
    // 上传营业执照
    imgChange(file, fileList) {
      this.noneBtnImg = fileList.length >= this.limitCountImg4
      //  console.log(file)
      // 图片后缀
      this.imgname = getType(file.name)

      function getType(file) {
        var index1 = file.lastIndexOf('.')
        var index2 = file.length
        var type = file.substring(index1, index2).toUpperCase()
        return type
      }
      var _this = this
      var event = event || window.event
      var file = event.target.files[0]
      var reader = new FileReader()
      reader.onload = function(e) {
        _this.dialogImageUrl = e.target.result.substring(
          e.target.result.indexOf(',') + 1,
          e.target.result.length
        ) // 将图片路径赋值给src
      }
      reader.readAsDataURL(file)
      //  console.log(this.dialogImageUrl)
      //  console.log( this.imgname)
    },

    handleImgRemove(file, fileList) {
      this.noneBtnImg = fileList.length >= this.limitCountImg
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      console.log(this.dialogImageUrl)
      this.dialogVisible = true
    },

    // 上传身份证正面
    imgChange_s(file, fileList) {
      this.noneBtnImg_s = fileList.length >= this.limitCountImg_s
      var _this = this
      var event = event || window.event
      var file = event.target.files[0]
      var reader = new FileReader()

      this.imgFname1 = getType(file.name)

      function getType(file) {
        var index1 = file.lastIndexOf('.')
        var index2 = file.length
        var type = file.substring(index1, index2).toUpperCase()
        return type
      }

      // 转base64
      reader.onload = function(e) {
        _this.dialogImageUrl_s = e.target.result.substring(
          e.target.result.indexOf(',') + 1,
          e.target.result.length
        ) // 将图片路径赋值给src
      }
      reader.readAsDataURL(file)
      //  console.log(this.dialogImageUrl_s)
    },
    handleImgRemove_s(file, fileList) {
      this.noneBtnImg_s = fileList.length >= this.limitCountImg_s
    },
    handlePictureCardPreview_s(file) {
      this.dialogImageUrl_s = file.url
      this.dialogVisible_s = true
    },
    // 上传身份证反面
    imgChange_x(file, fileList) {
      this.noneBtnImg_x = fileList.length >= this.limitCountImg_x
      var _this = this
      var event = event || window.event
      var file = event.target.files[0]
      var reader = new FileReader()
      this.imgFname2 = getType(file.name)

      function getType(file) {
        var index1 = file.lastIndexOf('.')
        var index2 = file.length
        var type = file.substring(index1, index2).toUpperCase()
        return type
      }
      // 转base64
      reader.onload = function(e) {
        _this.dialogImageUrl_x = e.target.result.substring(
          e.target.result.indexOf(',') + 1,
          e.target.result.length
        ) // 将图片路径赋值给src
      }
      reader.readAsDataURL(file)
      // console.log(this.dialogImageUrl_x)
    },
    handleImgRemove_x(file, fileList) {
      this.noneBtnImg_x = fileList.length >= this.limitCountImg_x
    },
    handlePictureCardPreview_x(file) {
      this.dialogImageUrl_x = file.url
      this.dialogVisible_x = true
    },
    // 提交企业信息
    immediately() {
      console.log(this.dialogImageUrl) // bass 转换
      const imgFname = this.dialogImageUrl_s + ' ' + this.dialogImageUrl_x
      const Houzhui = this.imgFname1 + ' ' + this.imgFname2

      console.log(Houzhui)
      if (
        this.name_comp.length > 0 &&
          this.credit.length == 18 &&
          this.dialogImageUrl.length != 0 &&
          this.name.length > 0 &&
          /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.text_phone)
          // this.dialogImageUrl_s.length > 0 &&
          // this.dialogImageUrl_x.length > 0
      ) {
        add_ent_aut_info({
          data: {
            ent_name: this.name_comp, // 企业名称
            uscc: this.credit, // 统一社会信用代码
            license_data: this.dialogImageUrl, // 营业执照图片
            license_data_type: this.imgname, // 营业执照图片后缀
            lar_name: this.name, // 法人
            lar_mobile: this.text_phone, // 法人电话
            lar_idcard_data: this.dialogImageUrl_s + ' ' + this.dialogImageUrl_x, // 法人身份证图片
            lar_idcard_data_type: this.imgFname1 + ' ' + this.imgFname2, // 法人身份证图片后缀
            user_id: sessionStorage.getItem('user_id') // 用户id
          }
        }).then(res => {
          console.log(res)
          console.log(this.dialogImageUrl)
          console.log(this.imgname)
          console.log(this.dialogImageUrl_s + ' ' + this.dialogImageUrl_x)
          console.log(this.imgFname1 + ' ' + this.imgFname2)
          if (res.code == 10085) {
            this.$router.push('/EnterpriseSubmit') // 继续和返回
            this.$message({
              message: '信息提交成功',
              type: 'success'
            })
          } else if (res.code == 10086) {
            this.$message({
              message: '信息提交失败，请稍后重试',
              type: 'error'
            })
          } else if (res.code == 600) {
            this.$message({
              message: '您输入的社会信用代码已存在',
              type: 'error'
            })
          } else if (res.code == '1200' || res.code == '1100') {
            this.$message({
              message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请您完善信息',
          type: 'error'
        })
      }
    },

    // 检查信用代码是否被注册
    social() {
      aut_ent_have_exist({
        data: {
          uscc: this.credit,
          ent_name: this.name_comp
        }
      }).then(res => {
        console.log(res)
        if (this.credit != '') {
          if (res.code == 600) {
            this.$message({
              message: '您输入的社会信用代码已存在',
              type: 'error'
            })
          } else {}
          if (this.credit.length == 18) {
            if (res.code == 700) {
              this.$message({
                message: '社会信用代码可用',
                type: 'success'
              })
            }
            if (res.code == 12700) {
              this.$message({
                message: '该信用代码正在注销保护期内，无法新增企业，如需帮助请联系客服',
                type: 'error'
              })
            }
          }
        } else {
          this.$message({
            message: '社会信用代码不能为空',
            type: 'error'
          })
        }
      })
    },

    crumbs() {
      this.$router.push('/EnteroriseNewly')
    }

  }
}

</script>

<style scoed lang="scss">
  .el-main {

    background: #f6f6fa;
  }

  .Enterprise-p {
    font-size: 18px;
    color: #999999;
    display: flex;
    margin: 26px 0 30px 0;

    span {
      margin: 4px;
    }

    i {
      background: url('../../../assets/img/din.png') no-repeat;
      width: 23px;
      height: 30px;
      display: block;
      margin-right: 11px;
    }
  }

  .Enterprise {
    .pictureS {
      width: 140px;

    }

    .Enterprise-mess-shang {
      img {
        width: 180px;
        height: 160px;
      }

      &>img:first-of-type {
        margin-right: 80px;
      }
    }

    width: 100%;
    color: #f6f6f3;
    background: #fefefe;
    -moz-box-shadow: 0px 0px 10px #e8e8eb;
    -webkit-box-shadow: 0px 0px 10px #e8e8eb;
    box-shadow: 0px 0px 10px #e8e8eb;

    .Enterprise-mess-shang {
      &>p {
        font-size: 16px;
        color: #999;
        margin-top: 100px;
      }

      display: flex;

      .uoloadSty_s {
        margin-right: 40px;

        .el-icon-plus {
          font-size: 0;
          color: transparent;
        }
      }
    }

    .Enterprise-title {
      display: flex;
      width: 100%;
      height: 80px;
      background: #f6f8fa;
      border-radius: 10px 10px 0px 0px;
      align-items: center;
      box-sizing: border-box;
      padding-left: 30px;

      span {
        width: 6px;
        height: 30px;
        background: linear-gradient(221deg, #fc4353, #ff5e41);
        border-radius: 3px;
        margin-right: 11px;
      }

      p {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }

    .p {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #cccccc;
    }

    .Enterprise-mess {
      background: #fff;
      padding-top: 30px;

      &:last-of-type {
        padding-bottom: 75px;
      }

      .Enterprise-mess-name {
        display: flex;
        margin-bottom: 40px;

        &:nth-child(3) {
          margin-bottom: 0;
          padding-bottom: 104px;
        }

        .Enterprise-mess-name-p {
          font-size: 14px;
          color: #999999;
        }

        &:nth-child(3)>span {
          margin: 20px;
        }

        &:nth-child(3) p {
          margin: 50px 0 0 20px;
        }

        .Enterprise-mess-shang {
          &>p {
            font-size: 16px;
            color: #999;
            margin-top: 140px;
          }
        }

        &>span {
          font-size: 16px;
          width: 150px;
          display: block;
          text-align: right;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          margin: 10px 19px 0 30px;
        }
      }
    }

    .ent-button {
      width: 130px;
      height: 50px;
      background: linear-gradient(200deg, #fc4353, #ff5e41);
      border-radius: 10px;
      text-align: center;
      color: #fff;
      font-size: 20px;
      line-height: 50px;
      margin: 0 auto;

      &:hover {
        cursor: pointer;
      }
    }
  }

</style>
